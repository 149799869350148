import React from 'react';
import { Formik, Form } from 'formik';

import Yup from '@components/Form/Yup/variants/2';
import FieldWrapper from '@components/Form/FieldWrapper';
import { ErrorMessage2 } from '@components/Form/ErrorMessage';
import { Label1 } from '@components/Form/Label';
import Submit from '@components/Button/Submit/1';
import { createPropsFunc, options } from '@components/Form/Formik/';

import { Name, Email, Message } from './Field';

import styles from './styles.module.css';
import { name, email, message, button } from './data.json';

const Label = props => (
  <Label1 className={styles.label}>{props.children}</Label1>
);

const NAME = 'name';
const EMAIL = 'email';
const MESSAGE = 'message';

export default props => (
  <Formik
    {...options.standard}
    initialValues={{ [NAME]: '', [EMAIL]: '', [MESSAGE]: '' }}
    onSubmit={(values, { resetForm }) => {
      props.onSubmit(values);
      resetForm();
    }}
    validationSchema={Yup.object({
      [NAME]: Yup.mixed().required(),
      [EMAIL]: Yup.string()
        .email()
        .required(),
      [MESSAGE]: Yup.string().required(),
    })}
  >
    {props => {
      const { errors, handleSubmit } = props;
      const assignProps = createPropsFunc(props);

      const ErrorMessage = ({ name }) => (
        <ErrorMessage2 isHidden={!errors[name]}>{errors[name]}</ErrorMessage2>
      );

      return (
        <Form
          className={styles.form}
          onSubmit={handleSubmit}
          noValidate="novalidate"
        >
          <FieldWrapper>
            <Label htmlFor={NAME}>{name.label}</Label>
            <Name {...assignProps(NAME)} autoFocus={true} />
            <ErrorMessage name={NAME} />
          </FieldWrapper>

          <FieldWrapper>
            <Label htmlFor={EMAIL}>{email.label}</Label>
            <Email {...assignProps(EMAIL)} />
            <ErrorMessage name={EMAIL} />
          </FieldWrapper>

          <FieldWrapper>
            <Label htmlFor={MESSAGE}>{message.label}</Label>
            <Message className={styles.textarea} {...assignProps(MESSAGE)} />
            <ErrorMessage name={MESSAGE} />
          </FieldWrapper>

          <Submit className={styles.submit}>{button}</Submit>
        </Form>
      );
    }}
  </Formik>
);
