import React from 'react';
import styles from './styles.module.css';

export default function City({ name, phone, address }) {
  return (
    <div className={styles.container}>
      <h2 className={styles.name}>{name}</h2>
      <div>
        <a className={styles.phone} href={phone.href}>
          {phone.text}
        </a>
      </div>
      <p
        className={styles.address}
        dangerouslySetInnerHTML={{ __html: address }}
      />
    </div>
  );
}
