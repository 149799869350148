import React from 'react';
import Body from '@sections/Contact';
import Layout from '@layouts/business';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO
      title="Контакты | IMstream"
      description="IMstream – профессиональный сервис по предоставлению музыки для бизнеса. Свяжитесь с нами удобным для вас способом и мы ответим на ваши вопросы."
    />
    <Body />
  </Layout>
);
