import { telegram, whatsapp } from '@data/contacts.json';
import { WhatsApp2 as WhatsApp, Telegram2 as Telegram } from '@icons';

export default [
  {
    title: 'Телеграм',
    href: telegram.href,
    Icon: Telegram,
  },
  {
    title: 'WhatsApp',
    href: whatsapp.href,
    Icon: WhatsApp,
  },
];
