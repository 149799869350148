import React, { Fragment } from 'react';

import Container from '@components/Container';
import PopUp from '@components/PopUp';
import FormWithPopUp from '@components/FormWithPopUp';

import postCurry from '@api/Mailer/post';

import Info from './Info';
import Form from './Form';
import GoogleMap from './Map';

import styles from './styles.module.css';

export default function() {
  const post = postCurry({ subject: 'Сообщение с сайта' });

  return (
    <Fragment>
      <div className={styles.container}>
        <Container className={styles.inner}>
          <h1 className={styles.title}>Свяжитесь с нами</h1>
          <div className={styles.wrapper}>
            <Info className={styles.info} />
            <div>
              <FormWithPopUp Form={Form} PopUp={PopUp} post={post} />
            </div>
          </div>
          <GoogleMap />
        </Container>
      </div>
    </Fragment>
  );
}
