import React from 'react';
import cx from 'classnames';

import FreePhone from './FreePhone';
import Cities from './Cities';
import MessangerList from './MessangerList';
import Email from './Email';

import styles from './styles.module.css';

export default ({ className }) => (
  <div className={cx(styles.container, className)}>
    <p className={styles.text}>
      Остались вопросы, которые бы вы хотели задать? Хотите получить юридическую
      консультацию? Напишите нам и мы на все ответим.
    </p>
    <div className={styles.wrapper}>
      <Cities />
      <div className={styles.phoneIcons}>
        <FreePhone />
        <MessangerList />
      </div>
      <Email />
    </div>
  </div>
);
