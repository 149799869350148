import React from 'react';
import styles from './styles.module.css';

import City from '../City';
import cities from './data';

export default function Cities() {
  return (
    <div className={styles.container}>
      {cities.map(({ name, phone, address }) => (
        <City key={name} name={name} phone={phone} address={address} />
      ))}
    </div>
  );
}
