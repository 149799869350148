import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import styles from './styles.module.css';
import data from './data.json';

const MapComponent = compose(
  withProps({
    googleMapURL: data.googleMapURL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className={styles.wrapper} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => (
  <GoogleMap
    defaultZoom={data.zoom}
    defaultCenter={{ ...data.position }}
    options={{
      styles: data.styles,
    }}
  >
    <Marker position={{ ...data.position }} />
  </GoogleMap>
));

export default () => <MapComponent />;
