import React from 'react';
import styles from './styles.module.css';

import messangers from './data';

export default () => (
  <ul className={styles.container}>
    {messangers.map(({ title, href, Icon }) => (
      <li key={title} className={styles.messanger}>
        <a className={styles.messangerLink} href={href}>
          <Icon className={styles.icon} />
        </a>
      </li>
    ))}
  </ul>
);
