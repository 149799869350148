import React from 'react';
import contacts from '@data/contacts.json';

import styles from './styles.module.css';

export default function FreePhone() {
  return (
    <div className={styles.container}>
      <p className={styles.text}>Бесплатный по России:</p>
      <a className={styles.link} href={contacts.phone.russia.href}>
        {contacts.phone.russia.text}
      </a>
    </div>
  );
}
